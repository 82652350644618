import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  LWrap,
  CJumbotron,
  CSectMedia,
  CCard,
  CCard02,
  CBreadCrumb,
  CSearch,
} from '../../../components/_index';
import '../../../assets/_sass/page/enjoy_the_city.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            main: '街を、楽しむ',
            sub: 'SIGHTSEEING',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/about/enjoy_the_city/kv.png',
              },
              imgSp: {
                src: '/assets/images/about/enjoy_the_city/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>
      <div className="p_enjoy_the_city">
        <section className="u_pt100 u_pb100">
          <LWrap>
            <p className="c_sectLead u_tac_pc">
              歴史と伝統を持った粋な下町「日本橋・人形町」の文化に触れ、
              <br />
              “ルームキーを持って”街を散策すると、老舗の魅力だけでなく素敵な特典も。
              <br />
              隅田川沿いのお散歩や七福神巡りなど、ご滞在中のひとときを存分にお楽しみください。
            </p>
          </LWrap>
        </section>

        <section>
          <LWrap>
            <CSectMedia
              data={[
                {
                  img: {
                    src: '/assets/images/about/enjoy_the_city/img_enjoy.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/about/enjoy_the_city/img_enjoy__sp.png',
                    alt: '',
                  },
                  link: {
                    href: '/assets/files/pdf/roomkey_information.pdf',
                    blank: true,
                  },
                  title: (
                    <>
                      ルームキーで「お得」粋な街
                      <br />
                      日本橋・人形町へ出かけよう!
                    </>
                  ),
                  text: (
                    <>
                      <p className="u_mb20_pc">
                        日本橋・人形町の文化に触れ、
                        <br className="u_sp" />
                        滞在型観光を楽しむ事で、地域との回遊性を高める事を目指しています。
                      </p>
                      <p>
                        店舗へ訪問またはお買い物の際“ルームキー”をご提示いただくと、特典を受けられるサービスです。
                      </p>
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/about/enjoy_the_city/img_enjoy02.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/about/enjoy_the_city/img_enjoy02__sp.png',
                    alt: '',
                  },
                  link: {
                    href: '/about/suitengu/',
                  },
                  title: <>水天宮のご案内</>,
                  text: (
                    <>
                      ロイヤルパークホテルに隣接する水天宮は、
                      <br />
                      江戸時代から安産・子授けの神様として信仰を集めてきました。
                      <br />
                      現代的で美しい社殿をぜひご参拝ください。
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>

        <section className="u_pt100 u_pb100">
          <LWrap>
            <CCard
              data={[
                {
                  img: {
                    src: '/assets/images/about/enjoy_the_city/img_enjoy03.png',
                    alt: '',
                  },
                  title: <>日本橋七福神巡り</>,
                  text: <>日本橋の七福神を巡るおすすめお散歩コース</>,
                  content: (
                    <>
                      <a
                        href="http://www.ningyocho.or.jp/contents/walk-course/shichifukujin.html"
                        target="_blank"
                      >
                        人形町商店街 公式サイト<i className="icon-blank"></i>
                      </a>
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/about/enjoy_the_city/img_enjoy04.png',
                    alt: '',
                  },
                  title: <>深川「おくのほそ道」ゆかりスポット</>,
                  text: <>松尾芭蕉ゆかりの地を散策しませんか。</>,
                  content: (
                    <>
                      <a
                        href="https://www.gotokyo.org/jp/story/walks-and-tours/oku-no-hosomichi-fukagawa/index.html"
                        target="_blank"
                      >
                        GO TOKYO 東京の観光公式サイト
                        <i className="icon-blank"></i>
                      </a>
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/about/enjoy_the_city/img_enjoy05.png',
                    alt: '',
                  },
                  title: <>隅田川</>,
                  text: (
                    <>
                      繊細なデザインの「清州橋」と雄大な「永代橋」を眺めることができ、ライトアップした美しい夜景もおすすめです。
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>

        <section className="u_pb100">
          <LWrap>
            <CCard02
              col={2}
              data={[
                {
                  img: {
                    src: '/assets/images/about/enjoy_the_city/img_link.png',
                    alt: '',
                  },
                  title: <>観光に便利なサービス</>,
                  content: <></>,
                  link: {
                    href: '/about/tourism/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/enjoy_the_city/img_link02.png',
                    alt: '',
                  },
                  title: <>ホテル周辺のご案内</>,
                  content: <></>,
                  link: {
                    href: '/about/surroundings/',
                  },
                },
              ]}
            />
          </LWrap>
        </section>
      </div>

      <CBreadCrumb
        data={{
          parent: [
            {
              label: 'ホテルについて',
              path: '/about/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <CSearch formId="global" />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
